import http from 'http/index';
import { cas3ApiRoute } from 'http/utility';

import downloadBase64 from 'helpers/downloadBase64';
import downloadFile from 'helpers/downloadFile';

import {
  format,
  scalesFormat,
  materialsFormat,
  assessmentDateFormat,
  assessmentsDateFormat,
  studentsFormat,
} from './formatter';

export const getAssessments = () => http.get(
  cas3ApiRoute('/data-collections'),
  { params: { role: 'teacher' } },
).then((res) => assessmentsDateFormat(res.data.dataCollections));

export const getAssessment = (id) => http.get(
  cas3ApiRoute(`/data-collections/${id}`),
  { params: { role: 'teacher' } },
).then((res) => assessmentDateFormat(res.data.dataCollection));

export const getAnnouncements = () => http.get(
  cas3ApiRoute('/announcements'),
).then((res) => res.data.announcements);

export const getCollectionItems = (id) => http.get(
  cas3ApiRoute(`/data-collections/${id}/items`),
).then((res) => res.data.assessmentItems);

export const getItemMaterials = ({ collectionId }) => http.get(
  cas3ApiRoute(`/data-collections/${collectionId}/items/resources`),
).then((res) => materialsFormat(collectionId, res));

export const getRatingSheetsReport = (collectionId) => http
  .post(cas3ApiRoute('/score-sheet/template/all'), { dataCollectionId: +collectionId })
  .then((res) => downloadBase64(res.data.pdf, 'Rating_Sheets_for_All_Students.pdf'));

export const getIsrsReport = ({ collectionId, params }) => downloadFile(
  cas3ApiRoute(`/data-collections/${collectionId}/outputs/download`),
  'Student ISRs.pdf',
  params,
);

export const getCollectionScales = (id) => http.get(
  cas3ApiRoute(`/data-collections/${id}/scales`),
).then((res) => scalesFormat(res.data.scales));

export const getCollectionSets = (id) => http.get(
  cas3ApiRoute(`/data-collections/${id}/sets`),
).then((res) => format(res.data.dataCollectionsSets));

export const getCollectionHierarchy = (id) => http.get(
  cas3ApiRoute(`/data-collections/${id}/hierarchy-items`),
).then((res) => format(res.data.hierarchyItems));

export const getCollectionStudents = ({ data: id }) => http.get(
  cas3ApiRoute(`/data-collections/${id}/students/legacy`),
).then((res) => studentsFormat(res.data, id));

export const getCollectionGrades = (id) => http.get(
  cas3ApiRoute(`/data-collections/${id}/grades`),
).then((res) => res.data);

export const putStudentAssessmentType = (data) => http.put(
  cas3ApiRoute('/data-collections-student-assignments'),
  data,
).then((res) => res.data);

export const getTenantMilestones = () => http.get(
  cas3ApiRoute('/tenant-milestone'),
).then((res) => res.data.tenantMilestones);

export const getScoreMilestones = (dataCollectionId) => http.get(
  cas3ApiRoute('/score-milestone/active'),
  { params: { dataCollectionId } },
).then((res) => res.data.scoreMilestones);

export const postCaptureMilestone = (studentId, dataCollectionId, tenantMilestoneId) => http.post(
  cas3ApiRoute('/score-milestone/capture'),
  { studentId, dataCollectionId, tenantMilestoneId },
).then((res) => res.data);
